<template>
  <section id="phone-statistics">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showPhoneCallsOverview" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of answered phone calls by employee
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-answered-phonecalls-employee" />
              </h4>
              <b-popover target="popover-number-answered-phonecalls-employee" triggers="hover" placement="bottom">
                <span>The 'Number of Answered Phone Calls by Employee' section features a bar chart that illustrates the
                  number of answered phone calls by each employee, grouped by months. This visual representation helps
                  users identify trends and patterns in employee performance over time. By analyzing the chart, users
                  can easily spot any significant changes in the number of answered calls, such as seasonal fluctuations
                  or the impact of staffing changes. Additionally, this section provides valuable insights for
                  optimizing workforce management and improving overall customer service efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberAnsweredPhoneCallsByEmployeeChart" :options="numberAnsweredPhoneCallsByEmployeeChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Availability quote by employee
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-availability-quote-employee" />
              </h4>
              <b-popover target="popover-availability-quote-employee" triggers="hover" placement="bottom">
                <span>The 'Availability Quote by Employee' section features a bar chart that illustrates the
                  availability quote for each employee, grouped by months. This visual representation helps users
                  identify trends and patterns in employee availability over time. By analyzing the chart, users can
                  easily spot any significant changes in availability, such as seasonal variations or the impact of
                  scheduling adjustments. Additionally, this section provides valuable insights for optimizing workforce
                  planning and ensuring adequate staffing levels to meet demand.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="availabilityQuoteByEmployeeChart" :options="availabilityQuoteByEmployeeChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Total length of answered phone calls
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-total-length-answered-phonecalls" />
              </h4>
              <b-popover target="popover-total-length-answered-phonecalls" triggers="hover" placement="bottom">
                <span>The 'Total Length of Answered Phone Calls' section features a bar chart that illustrates the total
                  length of answered phone calls, grouped by months. This visual representation helps users identify
                  trends and patterns in call duration over time. By analyzing the chart, users can easily spot any
                  significant changes in the total length of calls, such as seasonal fluctuations or the impact of
                  customer service initiatives. Additionally, this section provides valuable insights for optimizing
                  call handling processes and improving overall customer interaction efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="totalLenAnsweredPhoneCallsChart" :options="totalLenAnsweredPhoneCallsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. length of answered phone calls
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-length-answered-phonecalls" />
              </h4>
              <b-popover target="popover-avg-length-answered-phonecalls" triggers="hover" placement="bottom">
                <span>The 'Average Length of Answered Phone Calls' section features a bar chart that illustrates the
                  average length of answered phone calls by each employee, grouped by months. This visual representation
                  helps users identify trends and patterns in call duration among employees over time. By analyzing the
                  chart, users can easily spot any significant changes in the average length of calls, such as the
                  impact of training programs or changes in call handling procedures. Additionally, this section
                  provides valuable insights for optimizing employee performance and enhancing overall customer service
                  quality.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgLenAnsweredPhoneCallsChart" :options="avgLenAnsweredPhoneCallsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of answered phone calls by target alias
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-answered-phonecalls-target-alias" />
              </h4>
              <b-popover target="popover-number-answered-phonecalls-target-alias" triggers="hover" placement="bottom">
                <span>
                  The 'Number of Answered Phone Calls by Target Alias' section features a bar chart that illustrates the
                  number of answered phone calls by each target alias, grouped by months. This visual representation
                  helps users identify trends and patterns in call activity across different target aliases over time.
                  By analyzing the chart, users can easily spot any significant changes in the number of answered calls,
                  such as seasonal variations or the impact of marketing campaigns. Additionally, this section provides
                  valuable insights for optimizing communication strategies and improving overall customer
                  engagement.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberAnsweredPhoneCallsByTargetAliasChart"
              :options="numberAnsweredPhoneCallsByTargetAliasChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of total vs number of missed phone calls
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-total-number-missed-phonecalls" />
              </h4>
              <b-popover target="popover-number-total-number-missed-phonecalls" triggers="hover" placement="bottom">
                <span>
                  The 'Number of Total vs Number of Missed Phone Calls' section features a bar chart that illustrates
                  the total number of phone calls compared to the number of missed phone calls, grouped by months. This
                  visual representation helps users identify trends and patterns in call handling efficiency over time.
                  By analyzing the chart, users can easily spot any significant changes in the ratio of total to missed
                  calls, such as the impact of staffing levels or changes in call management procedures. Additionally,
                  this section provides valuable insights for optimizing call center performance and improving overall
                  customer service responsiveness.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberTotalvsMissedPhoneCallsChart" :options="numberTotalvsMissedPhoneCallsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. phone calls by day of the week of the last 3 months
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-phone-calls-day-week" />
              </h4>
              <b-popover target="popover-avg-phone-calls-day-week" triggers="hover" placement="bottom">
                <span>The 'Average Phone Calls by Day of the Week for the Last 3 Months' section features a bar chart
                  that illustrates the average number of phone calls received each day of the week over the last three
                  months. This visual representation helps users identify trends and patterns in call volume across
                  different days of the week. By analyzing the chart, users can easily spot any significant variations
                  in call activity, such as peak days or quieter periods. Additionally, this section provides valuable
                  insights for optimizing staffing schedules and improving overall call handling efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="phoneCallsByDayOfWeekChart" :options="phoneCallsByDayOfWeekChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. phone calls by daytime of the last 3 months
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-phonecalls-daytime" />
              </h4>
              <b-popover target="popover-avg-phonecalls-daytime" triggers="hover" placement="bottom">
                <span>
                  The 'Average Phone Calls by Daytime for the Last 3 Months' section features a bar chart that
                  illustrates the average number of phone calls received at different times of the day over the last
                  three months. This visual representation helps users identify trends and patterns in call volume
                  across various times of the day. By analyzing the chart, users can easily spot any significant
                  variations in call activity, such as peak hours or quieter periods. Additionally, this section
                  provides valuable insights for optimizing staffing schedules and improving overall call handling
                  efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="phoneCallsByDaytimeChart" :options="phoneCallsByDaytimeChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Phone calls by week of the last 3 months
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-phonecalls-week" />
              </h4>
              <b-popover target="popover-phonecalls-week" triggers="hover" placement="bottom">
                <span>The 'Phone Calls by Week for the Last 3 Months' section features a bar chart that illustrates the
                  number of phone calls received each week over the last three months. This visual representation helps
                  users identify trends and patterns in call volume on a weekly basis. By analyzing the chart, users can
                  easily spot any significant changes in call activity, such as increases during certain weeks or
                  periods of decline. Additionally, this section provides valuable insights for optimizing call center
                  operations and improving overall customer service efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="phoneCallsByWeekChart" :options="phoneCallsByWeekChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. wait time vs max wait time
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-wait-time-max-wait-time" />
              </h4>
              <b-popover target="popover-avg-wait-time-max-wait-time" triggers="hover" placement="bottom">
                <span>The 'Average Wait Time vs. Maximum Wait Time' section features a bar chart that illustrates the
                  average wait time compared to the maximum wait time. This visual representation helps users identify
                  trends and patterns in wait times. By analyzing the chart, users can easily spot any significant
                  discrepancies between average and maximum wait times, such as the impact of peak hours or staffing
                  levels. Additionally, this section provides valuable insights for optimizing service processes and
                  improving overall customer satisfaction.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgWaitTimePhoneCallsChart" :options="avgWaitTimePhoneCallsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Most called numbers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-most-called-numbers" />
          </h4>
          <b-popover target="popover-most-called-numbers" triggers="hover" placement="bottom">
            <span>The 'Most Called Numbers' section features a table that lists the most frequently called numbers. This
              tabular representation helps users identify trends and patterns in outbound call activity. By analyzing
              the table, users can easily spot the most common call recipients, which can provide insights into customer
              engagement and communication priorities. Additionally, this section offers valuable information for
              optimizing call strategies and improving overall customer relationship management.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="mostCalledNumbersTable.currentPage"
          :per-page="10" :items="mostCalledNumbersTable.items" :fields="mostCalledNumbersTable.fields"
          :sort-by.sync="mostCalledNumbersTable.sortBy" :sort-desc.sync="mostCalledNumbersTable.sortDesc"
          :sort-direction="mostCalledNumbersTable.sortDirection" :filter="mostCalledNumbersTable.filter"
          :filter-included-fields="mostCalledNumbersTable.filterOn">
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="mostCalledNumbersTable.currentPage" :total-rows="mostCalledNumbersTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BRow,
  BCol,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showPhoneCallsOverview: true,
      oldDateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      queryParams: {},
      totalLenAnsweredPhoneCallsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgLenAnsweredPhoneCallsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberAnsweredPhoneCallsByEmployeeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      availabilityQuoteByEmployeeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberTotalvsMissedPhoneCallsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      numberAnsweredPhoneCallsByTargetAliasChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgWaitTimePhoneCallsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      mostCalledNumbersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'source',
            label: 'number',
            sortable: true,
          },
          {
            key: 'customer_name',
            label: 'customer name',
            sortable: true,
          },
          {
            key: 'count',
            label: 'count',
            sortable: true,
          },
          {
            key: 'created_date',
            label: 'date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      phoneCallsByDayOfWeekChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      phoneCallsByDaytimeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      phoneCallsByWeekChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getPhoneCallsOverview();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    updatePhoneCallsCharts(data) {
      const dataAnsweredPhoneCalls = data[0].data.results;
      const dataMissedPhoneCalls = data[1].data.results;
      const dataAvgWaitTime = data[2].data.results;
      const dataAnsweredPhoneCallsByTargetAlias = data[3].data.results;
      const dataTotalPhoneCalls = data[4].data.results;
      const dataMostCalledNumbers = data[5].data.results;
      const dataPhoneCallsByDaytime = data[6].data.results;
      const dataPhoneCallsByDayOfWeek = data[7].data.results;
      const dataPhoneCallsByWeek = data[8].data.results;

      // Answered calls
      let employeeNames = dataAnsweredPhoneCalls.map(item => item.responder_alias);
      let dates = dataAnsweredPhoneCalls.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      employeeNames = [...new Set(employeeNames)];
      dates = [...new Set(dates)];

      const totalLenAnsweredPhoneCallsChartDatasets = [];
      const availabilityQuoteByEmployeeChartDatasets = [];
      const avgLenAnsweredPhoneCallsChartDatasets = [];
      const numberAnsweredPhoneCallsByEmployeeChartDatasets = [];

      let findValues;
      for (var i = 0; i < employeeNames.length; i++) {
        const totalLenAnsweredPhoneCalls = [];
        const availabilityQuoteByEmployee = [];
        const avgLenAnsweredPhoneCalls = [];
        const numberAnsweredPhoneCalls = [];
        const employee = employeeNames[i];

        for (var j = 0; j < dates.length; j++) {
          findValues = false;
          for (var k = 0; k < dataAnsweredPhoneCalls.length; k++) {
            const dateObj = new Date(dataAnsweredPhoneCalls[k].created_date);
            let d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

            if (employee === dataAnsweredPhoneCalls[k].responder_alias && dates[j] === d) {
              totalLenAnsweredPhoneCalls.push(dataAnsweredPhoneCalls[k].total_len);
              availabilityQuoteByEmployee.push((dataAnsweredPhoneCalls[k].count / dataTotalPhoneCalls[j].count * 100).toFixed(2));
              avgLenAnsweredPhoneCalls.push(dataAnsweredPhoneCalls[k].avg_duration);
              numberAnsweredPhoneCalls.push(dataAnsweredPhoneCalls[k].count);
              findValues = true;
              dataAnsweredPhoneCalls.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            totalLenAnsweredPhoneCalls.push(0.0);
            availabilityQuoteByEmployee.push(0.0);
            avgLenAnsweredPhoneCalls.push(0.0);
            numberAnsweredPhoneCalls.push(0.0);
          }
        }

        totalLenAnsweredPhoneCallsChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: totalLenAnsweredPhoneCalls,
        });

        availabilityQuoteByEmployeeChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: availabilityQuoteByEmployee,
        });

        avgLenAnsweredPhoneCallsChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: avgLenAnsweredPhoneCalls,
        });

        numberAnsweredPhoneCallsByEmployeeChartDatasets.push({
          name: employee,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? employee : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: numberAnsweredPhoneCalls,
        });
      }

      this.totalLenAnsweredPhoneCallsChart.xAxis.data = dates;
      this.totalLenAnsweredPhoneCallsChart.series = totalLenAnsweredPhoneCallsChartDatasets;

      this.availabilityQuoteByEmployeeChart.xAxis.data = dates;
      this.availabilityQuoteByEmployeeChart.series = availabilityQuoteByEmployeeChartDatasets;

      this.avgLenAnsweredPhoneCallsChart.xAxis.data = dates;
      this.avgLenAnsweredPhoneCallsChart.series = avgLenAnsweredPhoneCallsChartDatasets;

      this.numberAnsweredPhoneCallsByEmployeeChart.xAxis.data = dates;
      this.numberAnsweredPhoneCallsByEmployeeChart.series = numberAnsweredPhoneCallsByEmployeeChartDatasets;

      // Missed calls
      let findValuesTotalPhoneCalls;
      let findValuesMissedPhoneCalls;
      dates = dataTotalPhoneCalls.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      dates = [...new Set(dates)];

      const numberTotalvsMissedPhoneCallsChartDatasets = [];
      const numberTotalPhoneCalls = [];
      const numberMissedPhoneCalls = [];
      for (j = 0; j < dates.length; j++) {
        findValuesTotalPhoneCalls = false;
        for (k = 0; k < dataTotalPhoneCalls.length; k++) {
          const dateObj = new Date(dataTotalPhoneCalls[k].created_date);
          let d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

          if (dates[j] === d) {
            numberTotalPhoneCalls.push(dataTotalPhoneCalls[k].count);
            findValuesTotalPhoneCalls = true;
            dataTotalPhoneCalls.splice(k, 1);
            break;
          }
        }
        if (!findValuesTotalPhoneCalls) {
          numberTotalPhoneCalls.push(0.0);
        }

        findValuesMissedPhoneCalls = false;
        for (k = 0; k < dataMissedPhoneCalls.length; k++) {
          if (dates[j] === `${new Date(dataMissedPhoneCalls[k].created_date).getMonth() + 1}.${new Date(dataMissedPhoneCalls[k].created_date).getFullYear()}`) {
            numberMissedPhoneCalls.push(dataMissedPhoneCalls[k].count);
            findValuesMissedPhoneCalls = true;
            dataMissedPhoneCalls.splice(k, 1);
            break;
          }
        }
        if (!findValuesMissedPhoneCalls) {
          numberMissedPhoneCalls.push(0.0);
        }
      }

      numberTotalvsMissedPhoneCallsChartDatasets.push({
        name: 'Number of total calls',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: numberTotalPhoneCalls,
      });

      numberTotalvsMissedPhoneCallsChartDatasets.push({
        name: 'Number of missed calls',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: numberMissedPhoneCalls,
      });

      this.numberTotalvsMissedPhoneCallsChart.xAxis.data = dates;
      this.numberTotalvsMissedPhoneCallsChart.series = numberTotalvsMissedPhoneCallsChartDatasets;

      // Answered calls by Target Alias
      let targetAlias = dataAnsweredPhoneCallsByTargetAlias.map(item => item.target_alias);
      dates = dataAnsweredPhoneCallsByTargetAlias.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      targetAlias = [...new Set(targetAlias)];
      dates = [...new Set(dates)];

      const numberAnsweredPhoneCallsByTargetAliasChartDatasets = [];
      for (i = 0; i < targetAlias.length; i++) {
        const numberAnsweredPhoneCallsByTargetAlias = [];
        for (j = 0; j < dates.length; j++) {
          findValues = false;
          for (k = 0; k < dataAnsweredPhoneCallsByTargetAlias.length; k++) {
            const dateObj = new Date(dataAnsweredPhoneCallsByTargetAlias[k].created_date);
            let d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

            if (targetAlias[i] === dataAnsweredPhoneCallsByTargetAlias[k].target_alias && dates[j] === d) {
              numberAnsweredPhoneCallsByTargetAlias.push(dataAnsweredPhoneCallsByTargetAlias[k].count);
              findValues = true;
              dataAnsweredPhoneCallsByTargetAlias.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            numberAnsweredPhoneCallsByTargetAlias.push(0.0);
          }
        }

        numberAnsweredPhoneCallsByTargetAliasChartDatasets.push({
          name: targetAlias[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: numberAnsweredPhoneCallsByTargetAlias,
        });
      }

      this.numberAnsweredPhoneCallsByTargetAliasChart.xAxis.data = dates;
      this.numberAnsweredPhoneCallsByTargetAliasChart.series = numberAnsweredPhoneCallsByTargetAliasChartDatasets;

      // Avg. wait time
      dates = dataAvgWaitTime.map(item => `${new Date(item.created_date).getMonth() + 1}.${new Date(item.created_date).getFullYear()}`);
      dates = [...new Set(dates)];

      const avgWaitTimePhoneCallsChartDatasets = [];
      const avgWaitTimePhoneCalls = [];
      const maxWaitTimePhoneCalls = [];
      for (j = 0; j < dates.length; j++) {
        findValues = false;
        for (k = 0; k < dataAvgWaitTime.length; k++) {
          const dateObj = new Date(dataAvgWaitTime[k].created_date);
          let d = `${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;

          if (dates[j] === d) {
            avgWaitTimePhoneCalls.push(dataAvgWaitTime[k].avg_duration);
            maxWaitTimePhoneCalls.push(dataAvgWaitTime[k].max_duration);
            findValues = true;
            dataAvgWaitTime.splice(k, 1);
            break;
          }
        }
        if (!findValues) {
          avgWaitTimePhoneCalls.push(0.0);
          maxWaitTimePhoneCalls.push(0.0);
        }
      }

      avgWaitTimePhoneCallsChartDatasets.push({
        name: 'Avg. wait time',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: avgWaitTimePhoneCalls,
      });

      avgWaitTimePhoneCallsChartDatasets.push({
        name: 'Max wait time',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: maxWaitTimePhoneCalls,
      });

      this.avgWaitTimePhoneCallsChart.xAxis.data = dates;
      this.avgWaitTimePhoneCallsChart.series = avgWaitTimePhoneCallsChartDatasets;

      // Most called number
      this.mostCalledNumbersTable.items = dataMostCalledNumbers;
      this.mostCalledNumbersTable.totalRows = dataMostCalledNumbers.length * 2;

      // Phone calls by daytime
      this.phoneCallsByDaytimeChart.xAxis.data = dataPhoneCallsByDaytime.map(item => item.hour);
      this.phoneCallsByDaytimeChart.series = {
        name: "Avg. phonecalls",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: dataPhoneCallsByDaytime.map(item => item.avg_per_hour),
      };

      // Phone calls by day of the week
      this.phoneCallsByDayOfWeekChart.xAxis.data = dataPhoneCallsByDayOfWeek.map(item => item.day_of_week_str);
      this.phoneCallsByDayOfWeekChart.series = {
        name: "Avg. phonecalls",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: dataPhoneCallsByDayOfWeek.map(item => item.avg_per_day),
      };

      // Phone calls by week
      this.phoneCallsByWeekChart.xAxis.data = dataPhoneCallsByWeek.map(item => item.week);
      this.phoneCallsByWeekChart.series = {
        name: "Phonecalls",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: dataPhoneCallsByWeek.map(item => item.total_calls),
      };
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getPhoneCallsOverview() {
      this.showPhoneCallsOverview = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/answered-phonecalls-by-employee/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/missed-phonecalls/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/avg-wait-time-phonecalls/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/answered-phonecalls-by-target-alias/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/total-phonecalls/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/most-called-numbers/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/phonecalls-by-daytime/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/phonecalls-by-day-of-week/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/phonecalls-by-week/`, this.queryParams),
        ]);
        this.updatePhoneCallsCharts(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPhoneCallsOverview = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getPhoneCallsOverview();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.numberAnsweredPhoneCallsByEmployeeChart.refresh();
          this.$refs.availabilityQuoteByEmployeeChart.refresh();
          this.$refs.totalLenAnsweredPhoneCallsChart.refresh();
          this.$refs.avgLenAnsweredPhoneCallsChart.refresh();
          this.$refs.numberAnsweredPhoneCallsByTargetAliasChart.refresh();
          this.$refs.numberTotalvsMissedPhoneCallsChart.refresh();
          this.$refs.phoneCallsByDayOfWeekChart.refresh();
          this.$refs.phoneCallsByDaytimeChart.refresh();
          this.$refs.phoneCallsByWeekChart.refresh();
          this.$refs.avgWaitTimePhoneCallsChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>